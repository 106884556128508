<template>
  <div class="container-sos">
    <Header/>

    <p class="title">SOS</p>

    <switch-toggle v-model="state"/>

    <p class="information">Swipe right to call our aid tent.</p>
  </div>
</template>

<script>
  import Header from "@/components/Header";
  import SwitchToggle from "@/components/SwitchToggle";

  // import { Browser } from '@capacitor/browser';
  import { CallNumber } from 'capacitor-call-number';

  export default {
    name: "Sos",
    components: {
      Header,
      SwitchToggle
    },
    data: () => ({
      state: false,
    }),
    watch: {
      state: async function (val) {
        if(val) {
          await CallNumber.call({ number: '+4915124192064', bypassAppChooser: true });

        }
      }
    }
  }
</script>

<style lang="scss">
  .container-sos {
    height: calc(100vh - 50px);

    background: #27083A;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 76px;
      line-height: 91px;

      margin-bottom: 60px;

      color: #ff0080;
    }

    .information {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;

      margin-top: 30px;

      color: #ffb3fa;
    }
  }
</style>
