<template>
  <v-app>
    <v-main id="v-main">
      <router-view/>
    </v-main>

    <v-bottom-navigation
      background-color="primary"
      color="secondary"
      v-if="pages_shows.includes(route)"
      fixed
      dark
      height="74"
    >
      <v-btn to="/program_v3">
        Program

        <book-open-icon size="1.8x"/>
      </v-btn>

      <v-btn to="/favs">
        Favs

        <heart-icon size="1.8x"/>
      </v-btn>

      <v-btn to="/map">
        Map

        <map-icon size="1.8x"/>
      </v-btn>

      <v-btn to="/sos">
        Sos

        <phone-call-icon size="1.8x"/>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
  import { BookOpenIcon, HeartIcon, MapIcon, PhoneCallIcon } from 'vue-feather-icons'
  import UserService from "@/services/user.service";
  export default {
    name: 'App',
    data: function() {
      return {
        pages_shows: ['Program', 'Favs', 'Map', 'Forum', 'Sos', 'ProgramV3'],
      };
    },
    components: {
      BookOpenIcon,
      HeartIcon,
      MapIcon,
      PhoneCallIcon
    },
    computed: {
      route() {
        return this.$route.name
      },
    },

    async mounted () {

      try {
        const response = await UserService.getAppConfig();
        const value = response.data;
        this.$store.dispatch("user/updateAppConfig", value);
      } catch (error) {
        console.error("error while updating app config", error);
      }

    }
  }
</script>

<style lang="scss">
  .v-bottom-navigation {
    padding-bottom: 24px;
    z-index: 50 !important;

    .v-btn {
      height: 50px !important;

      font-size: 12px !important;

      text-transform: uppercase !important;
    }
  }
</style>
