<template>
  <div class="container-health-and-safety">
    <v-icon color="swimming" large @click="$router.go(-1)">arrow_back</v-icon>

    <p class="title">Health & Safety</p>

    <!-- <p class="date">07.03.2021</p> -->

    <p class="text">
      If you or other people you see need help, please use the SOS buttons and you will be connected to someone who will be taking care of the situation.
    </p>

    <p class="subtext">
      The festival is an ideal context to be playful and experiment, but we really strive to foster an environment predicated with an ethos of love & care. This starts with how we look after ourselves and those around us. Our awareness & first aid team in 2019 did a spectacular job, resulting in not a single guest going to the hospital. We are adding more trained professionals and medical staff this year and expanding our first aid awareness points. If you are not feeling well, reach out. If a friend is not doing well, don’t hide them in a tent. No judgement, find our team. If someone is alone and seems like they could use a little attention, step in or locate a staff member. Consume responsibly, drink water, eat, rest… don’t overdo it!
    </p>

    <p class="subtext">
      Our awareness team is lead by Berlin Anti-Violence Projekt, with assistance from TS Raver. Berlin AVP is a non-profit grass roots organization led by and for queer and trans people, providing dedicated services that focus on the intersection of nightlife & care. We are committed to creating a diverse team that inspires thoughtful harm reduction & safety. We will make a detailed post about how to locate our awareness team & where the awareness tents & first aid tents will be located on -site.
    </p>

    <p class="subtext">
      If you have medication that needs to be refrigerated, register at the Info Point.
    </p>
  </div>
</template>

<script>
export default {
  name: "HealthAndSafety",
};
</script>

<style lang="scss">
.container-health-and-safety {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  padding: 46px 50px 34px 24px;

  color: #019cec;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px;
  }

  .date {
    font-size: 16px;
    line-height: 24px;
  }

  .text {
    font-size: 29px;
    line-height: 34px;

    margin-bottom: 20px;
  }

  .subtext {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }
}
</style>
