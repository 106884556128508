<template>
  <div class="container-password">
    <div class="header">
      <v-icon class="icon-back" color="ambient" large @click="$router.go(-1)">
        arrow_back
      </v-icon>

      <p class="title">Forgot password</p>
    </div>

    <form class="form" autocomplete="off">
      <v-snackbar v-model="showMessage">
        <span>{{ errorMessage }}</span>
      </v-snackbar>

      <label>What is your email?</label>
      <v-text-field
        v-model="form.email"
        placeholder="E-mail"
        color="#ffb3fa"
        outlined
        :error-messages="emailErrors"
      />
    </form>

    <v-btn color="#019cec" dark @click="onSubmit()" :disabled="disableButton">DONE</v-btn>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import UserService from "@/services/user.service";

export default {
  name: "ForgotPassword",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
      },
      errorMessage: "",
      showMessage: false,
      disableButton: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Invalid email");
      !this.$v.form.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          this.disableButton = true;

          const response = await UserService.forgotPassword(this.form.email);

          this.errorMessage = response.data.status;
          this.showMessage = true;

          this.disableButton = false;
        } catch (error) {
          this.disableButton = false;

          if (error.response.data.error[0]) {
            this.errorMessage = error.response.data.error;
            this.showMessage = true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.container-password {
  height: 100vh;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  padding: 34px 50px 34px 34px;

  background-color: #27083a;

  .icon-back {
    width: 100%;

    display: flex;
  }

  color: #ffb3fa;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px;
  }

  .form {
    width: 100%;

    display: flex;
    flex-direction: column;

    .v-text-field--outlined fieldset {
      border: none;
    }

    .v-input {
      margin-bottom: 10px;

      .v-input__slot {
        background: #402d4f;
      }
    }

    label {
      margin-bottom: 2px;

      text-transform: uppercase;

      font-size: 14px;
      line-height: 16px;
    }

    input {
      color: #ffb3fa;

      padding-left: 10px;

      font-size: 18px;
    }

    ::placeholder {
      color: #ffb3fa;
    }

    button {
      font-size: 18px;

      height: 40px;
    }
  }
}
</style>
