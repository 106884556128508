<template>
  <div class="container-resgiter-success">
    <div class="header">
      <v-icon dark>check</v-icon>

      <p class="title">Successfully created your account.</p>

      <!-- <p class="subtext">Email confirmation sent to your email.</p> -->
    </div>

    <div class="container-button">
      <v-btn color="#019cec" dark @click="navigate()">DONE</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "resgister-success",
  data: () => ({}),
  methods: {
    navigate() {
      this.$store.dispatch("user/cleanRegister");

      this.$router.push('/welcome')
    }
  }
};
</script>

<style lang="scss">
.container-resgiter-success {
  height: 100%;
  flex: 1;

  color: #ffb3fa;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    margin: 50px 0px;
    margin-top: 120px;

    text-align: center;
  }

  .v-icon {
    background-color: #03ff68;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    margin-bottom: 30px;

    width: 44px;
    height: 44px;
  }

  .title {
    font-weight: 700;
    font-size: 29px;
    line-height: 35px;

    margin-bottom: 30px;
  }

  .subtext {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;
  }

  .container-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top: 10px;
  }
}
</style>
