import instance from './base.service';

class AuthService {
  async login(user) {
    return instance
      .post(`api/login.json`, {
        email: user.email,
        password: user.password,
        registration_token: user.registration_token
      })
      .then((response) => {
        return response;
      });
  }

  async getAppConfig() {
    return instance.get('api/app_config.json');
  }

  find() {
    return instance.post('api/users/find.json');
  }

  register(user) {
    return instance.post('api/users.json', user);
  }

  update(params) {
    return instance.put(`api/users/update_profile.json`, params)
  }

  delete() {
    return instance.delete(`api/users/destroy_profile.json`)
  }

  async registerAvatar(file, token) {
    try {
      const response = await fetch(`data:image/jpeg;base64,${file.base64String}`);

      const blob = await response.blob();

      const formdata = new FormData();
      formdata.append("avatar", blob);

      return instance.post('api/avatar.json', formdata, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    } catch (error) {
      console.error(error)
    }
  }

  forgotPassword(email) {
    return instance.post(`api/forgot-password.json`, {
      email
    });
  }

  resetPassword(data) {
    return instance.post(`api/reset-password.json`, data)
  }

  // confirm(token) {
  //   return instance.put(`${API_URL}confirmations/${token.token}.json`, {}).then(response => {
  //     return response.data;
  //   })
  // }
}

export default new AuthService();
