<template>
  <div :class="['container-artist']" :style="style">
    <v-btn class="icon-floating" color="primary" fab absolute @click="$router.go(-1)">
      <v-icon color="secondary">arrow_back</v-icon>
    </v-btn>

    <div v-if="imgError" style="height: 120px; width: 100%; background-color: transparent;">
    </div>
    <img v-else class="avatar" @error="onImgError" :src="schedule.attraction.image_url" alt="" />

    <div class="content">
      <div>
        <p :class="['title', { 'big-title': schedule.attraction.name.length > 50 }]">{{ schedule.attraction.name }}</p>
        <p class="subtitle">{{ schedule.attraction.description }}</p>
      </div>

      <div class="footer">
        <div class="footer-left">
          <div>
            <p>{{ weekDay }}</p>
            <p class="subtitle">{{ schedule.hour_start }}</p>
          </div>

          <div>
            <p>STAGE</p>
            <p class="subtitle">{{ schedule.stage.name }}</p>
          </div>
        </div>
      </div>

      <p class="favorite" v-if="!schedule.favorite_id" @click="onAddToFavClicked()">
        <icon-heart color="#019cec" /> Add to my favs
      </p>

      <p class="favorite" v-if="schedule.favorite_id" @click="removeToFavorites()">
        <icon-heart-fill color="#019cec" /> Remove from my favs
      </p>

      <transition>
        <div class="reminder-overlay" v-if="isShowReminder">
          <div class="reminder-content">
            <p>do you want to be reminded 30 minutes before?</p>
            <div class="buttons">
              <button class="button" @click.stop.prevent="onYesClicked(schedule)">YES</button>
              <button class="button" @click.stop.prevent="onNoClicked(schedule)">NO</button>
            </div>
          </div>
        </div>
      </transition>

      <p class="subtext" v-html="aboutComputed">
      </p>
    </div>
  </div>
</template>

<script>
import SchedulesService from "@/services/schedules.service";
import FavoritesService from "@/services/favorites.service";

export default {
  name: "Schedule",
  data: () => ({
    schedule: {
      attraction: {},
      stage: {},
    },
    isSignUp: false,
    dialogConfirm: false,
    dialogConfirmed: false,
    imgError: false,
    week_days: [
      'SUN',
      'MON',
      'TUE',
      'WED',
      'THU',
      'FRI',
      'SAT',
    ],
    isShowReminder: false
  }),
  mounted() {
    this.initialize();
  },
  computed: {
    style() {
      return `background-color: ${this.schedule.stage.color}`;
    },
    background() {
      return this.schedule.stage.name && this.schedule.stage.name.toLowerCase().split(" ").join("_");
    },
    weekDay() {
      // return this.week_days[this.$moment(this.schedule.date_start, "Europe/Berlin").day()]
      return this.$moment(this.schedule.date_start).locale('en').format("ddd").toUpperCase()
    },
    aboutComputed () {
      const breakTag = '<br>'
      const str = this.schedule && this.schedule.attraction ? this.schedule.attraction.about : ""
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
  },
  methods: {
    async initialize() {
      try {
        const response = await SchedulesService.show(this.$route.params.id);

        this.schedule = response.data;
      } catch (error) {
        console.error(error);
        this.$router.go(-1);
      }
    },
    async addToFavorites() {
      try {
        const response = await FavoritesService.create(this.schedule.id);

        this.schedule.favorite_id = response.data.favorite_id;
      } catch (error) {
        console.error(error);
        this.$router.go(-1);
      }
    },
    async removeToFavorites() {
      try {
        await FavoritesService.remove(this.schedule.favorite_id);

        this.schedule.favorite_id = null;
      } catch (error) {
        console.error(error);
        this.$router.go(-1);
      }
    },

    async onAddToFavClicked () {
      this.isShowReminder = true
    },

    async onYesClicked () {
      await this.addToFavorites()
      this.isShowReminder = false
    },

    async onNoClicked () {
      await this.addToFavorites()
      this.isShowReminder = false
    },

    openSignUp() {
      this.dialogConfirm = true;
    },
    openDialogConfirmed() {
      this.dialogConfirm = false;
      this.dialogConfirmed = true;

      this.isSignUp = true;
    },

    async onImgError () {
      this.imgError = true
    }
  },
};
</script>

<style lang="scss">
.container-artist {
  height: 100vh;

  overflow-y: scroll;

  padding-bottom: 80px;

  .icon-floating {
    top: 46px !important;
  }

  .v-btn {
    top: 25px;
    left: 25px;
  }

  .avatar {
    width: 100%;
    height: 342px;

    object-fit: cover;
  }

  .content {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin: 20px 35px;

    .title {
      font-weight: 700;
      font-size: 47px;
      line-height: 56px;

      color: #27083a;

      &.big-title {
        // line-break: anywhere;
        font-size: 34px;
      }
    }

    .subtitle {
      font-size: 29px;
      line-height: 35px;

      color: #27083a;
    }

    .footer {
      display: flex;
      justify-content: space-between;

      margin: 30px 0px;

      font-size: 12px;
      line-height: 18px;

      .subtitle {
        font-size: 16px;
        line-height: 24px;
      }

      .footer-left {
        display: flex;

        div + div {
          margin-left: 40px;
        }
      }
    }

    .favorite {
      display: flex;
      align-items: center;

      font-size: 18px;
      line-height: 21px;

      color: #019cec;
    }

    .text {
      font-size: 29px;
      line-height: 34px;

      margin: 30px 0px;

      color: #27083a;
    }

    .subtext {
      font-size: 18px;
      line-height: 27px;

      margin-top: 25px;
      margin-bottom: 20px;

      color: #27083a;
    }
  }
}
.dialog-confimed {
  display: flex;
  align-items: center;

  padding: 20px;

  overflow: hidden;

  .v-card__title {
    justify-content: center;
  }

  .v-icon.v-icon {
    display: flex;
    justify-content: center;

    margin: 20px 0px;
  }

  .v-card__text {
    display: flex;
    justify-content: center;
  }
}

</style>

<style lang="scss" scoped>
.reminder-overlay {
  // justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  backdrop-filter: blur(10px);
  border-left: 5px solid #000000;
  margin-top: 1rem;
  .reminder-content {
    // background-color: #11111c;
    // color: white;
    min-width: 300px;
    min-height: 70px;
    padding: 1rem;

    .buttons {
      .button {
        font-size: 18px;
        padding: 0.5rem 0.8rem;
        font-weight: bold;
        margin-right: 1rem;
      }
    }
  }
}
</style>
