import Vue from 'vue';
import Vuetify from 'vuetify';
import '@/stylesheets/vuetify.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#11111c',
        secondary: '#ffb3fa',
        black: '#27083A',
        accent: '#7B5A97',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        gallerie: '#a01ff2',
        beach_floor: "#31ece9",
        performance: '#F2C94C',
        ambient: '#ffb3fa',
        crane: '#ff0080',
        art_forest: '#03ff68',
        swimming: "#019cec"
      },
    },
  },
});
