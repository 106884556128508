<template>
  <div :class="['container-program-day', `program-${position}`]" :ref="group.date">
    <div class="header">
      <p class="day">{{ weekDay() }}</p>

      <p
        class="link"
        :style="`color: ${position == 0 ? '#402D4F' : '#019cec'}`"
        @click="sendPreviousDay(position)"
      >
        Previous day
        <v-icon :color="position == 0 ? '#402D4F' : '#019cec'" small>arrow_upward</v-icon>
      </p>
      <p
        class="link"
        :style="`color: ${position + 1 == total ? '#402D4F' : '#019cec'}`"
        @click="sendNextDay(position)"
      >
        Next day
        <v-icon :color="position + 1 == total ? '#402D4F' : '#019cec'" small>arrow_downward</v-icon>
      </p>
    </div>

    <div v-if="group.schedules.length > 0">
      <Item @onFavUpdated="onFavUpdated" v-for="program in group.schedules" :key="program.id" :program="program"/>
    </div>

    <div v-else>
      <div
        class="not-schedules"
      >
        <div/>

        <div class="content">
          <p class="title">No favs for Saturday :(</p>
          <p class="subtitle">
            To add Favs go to program and click on the 💛 icon.
          </p>
        </div>

        <div/>
      </div>
    </div>
  </div>
</template>

<script>
  import Item from "./Item";

  export default {
    name: "program-day",
    data: () => ({
      week_days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
    }),
    components: {
      Item,
    },
    props: {
      group: {
        type: Object,
        default: function () {
          return {}
        }
      },
      total: {
        type: Number,
        default: 0,
      },
      position: {
        type: Number,
        default: 0,
      },
      sendPreviousDay: {
        type: Function,
        default: () => {},
      },
      sendNextDay: {
        type: Function,
        default: () => {},
      },
    },
    methods: {
      weekDay() {
        return this.$moment(this.group.date).locale('en').format('dddd')
        // return this.week_days[this.$moment(this.group.date, "Europe/Berlin").day()]
      },

      onFavUpdated(params) {
        this.$emit('onFavUpdated', params);
      }
    }
  };
</script>

<style lang="scss">
  .container-program-day {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-around;

      background-color: #27083A;

      padding: 16px 0px;

      .day {
        color: #ffb3fa;

        margin-right: 10px;

        font-size: 18px;
      }

      .link {
        // color: #019cec;

        font-size: 14px;
      }
    }

    .not-schedules {
      background: #402D4F;

      display: grid;
      grid-template-columns: 1fr 10fr 1fr;
      grid-template-rows: 1fr 10fr;

      height: 196px;

      padding: 20px 16px;

      .title {
        font-weight: 700;
        font-size: 29px;
        line-height: 35px;

        color: #ffb3fa;
      }

      .subtitle {
        font-size: 18px;
        line-height: 27px;

        color: #ffb3fa;
      }
    }
  }
</style>
