<template>
  <div class="container-live-update">
    <div class="header">
      <v-icon color="crane" large @click="$router.go(-1)"> arrow_back </v-icon>

      <p class="title">Live Updates</p>
    </div>

    <div class="list-lives">
      <div
        class="live-item"
        v-for="update of live_updates"
        :key="update.id"
        @click="navigationToDetails(update.id)"
      >
        <p>{{update.title}} <br> {{$moment(update.created_at).format("DD.MM.YY-HH:mm")}}</p>

        <v-icon color="#ff0080">arrow_forward</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import LiveUpdatesService from "@/services/live.updates.service"

export default {
  name: "LiveUpdates",
  data: () => ({
    live_updates: [],
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    navigationToDetails(id) {
      this.$router.push(`/live-updates/${id}`);
    },
    async initialize() {
      try {
        const response = await LiveUpdatesService.getAll();

        this.live_updates = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.container-live-update {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  .header {
    padding: 46px 50px 30px 24px;
  }

  color: #ff0080;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
  }

  .list-lives {
    border-top: 1px solid #ff0080;
  }

  .live-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #ff0080;

    padding: 16px 24px;

    p {
      font-size: 16px;
      line-height: 24px;

      max-width: 250px;

      margin-right: 10px;
    }
  }
}
</style>
