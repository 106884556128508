<template>
  <div>
    <v-app-bar
      color="primary"
      fixed
      dark
      class="header"
    >
      <img class="logo-small" src="@/assets/logo_small.svg"/>

      <v-spacer/>

      <v-app-bar-nav-icon @click="drawer = true" color="secondary"/>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      class="sidebar-drawer burguer"
      width="100%"
      color="transparent"
    >
      <v-btn
        color="black"
        fab
        elevation="2"
        small
        @click="drawer = false"
      >
        <v-icon color="secondary">close</v-icon>
      </v-btn>

      <div class="content">
        <div>
          <div class="header-burger">
            <p class="name"> {{ user.name }}</p>
            <router-link to="/profile">Profile settings</router-link>
          </div>

          <div class="item" @click="navigationTo('/live-updates')">
            <p class="crane--text">Live Updates</p>
            <div class="icon">
              <icon-arrow color="#ff0080" />
            </div>
          </div>

          <div class="item" @click="navigationTo('/lineup')">
            <p class="beach_floor--text">Lineup</p>
            <div class="icon">
              <icon-arrow color="#31ece9" />
            </div>
          </div>

          <div class="item" @click="navigationTo('/location')">
            <p class="art_forest--text">The Location</p>
            <div class="icon">
              <icon-arrow color="#03ff68" />
            </div>
          </div>

          <div class="item" @click="navigationTo('/health-and-safety')">
            <p class="swimming--text">Health & Safety</p>
            <div class="icon">
              <icon-arrow color="#019cec" />
            </div>
          </div>

          <div class="item" @click="navigationTo('/faq')">
            <p class="gallerie--text">FAQ</p>
            <div class="icon">
              <icon-arrow color="#a01ff2" />
            </div>
          </div>
        </div>

        <div class="footer">
          <router-link to="/impressum">Impressum</router-link>
          <router-link to="/data-policy">Data policy</router-link>

          <p>app version [{{version}}]</p>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { App } from '@capacitor/app';
export default {
  name: "Header",
  data: () => ({
    drawer: false,
    group: null,
    version: "4.1"
  }),
  methods: {
    navigationTo(path) {
      this.$router.push(path);
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  async mounted () {
    const { version } = await App.getInfo();
    this.version = version
  }
}
</script>

<style lang="scss">
  .v-app-bar {
    z-index: 999;

    .logo-small {
      height: 21px;
      width: 21px;
    }

    height: calc(56px + env(safe-area-inset-top)) !important;

    padding-top: env(safe-area-inset-top);
  }

  .v-navigation-drawer__content {
    .content {
      background-color: #11111c;
      height: 100%;
      width: 85%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 20px;

      padding-top: calc(20px + env(safe-area-inset-top));
    }

    button {
      width: 40px;
      height: 40px;
      position: absolute !important;

      z-index: 99999;

      top: calc(20px + env(safe-area-inset-top));
      right: 15%;

      transform: translateX(20px);
    }

    .header-burger {
      margin-bottom: 30px;

      .name {
        font-size: 18px;
        line-height: 27px;

        color: #A79FAD;
      }

      a {
        font-size: 14px;
        line-height: 21px;

        color: #019cec;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;

      margin-bottom: 20px;

      padding-right: 20px;

      .icon {
        padding-top: 15px;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      p {
        font-weight: 700;
        font-size: 47px;
        line-height: 52px;

        color: #019cec;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;

      a {
        font-size: 12px;
        line-height: 14px;

        text-transform: uppercase;

        color: #DAD8DC;
      }

      p {
        font-size: 12px;
        line-height: 14px;

        text-transform: uppercase;

        color: #7B5A97;
      }
    }
  }

  .sidebar-drawer { z-index: 30; }
</style>
