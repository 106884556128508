<template>
  <div class="container-profile">
    <div>
      <div @click="$router.go(-1)">
        <icon-arrow color="#ffb3fa" :left="true"/>
      </div>

      <p class="title">Profile Settings</p>

      <p class="text">
        Hi, <span @click="$router.push('update-profile')">{{ user.name }}</span> <icon-edit/>
      </p>

      <p class="text" @click="$router.push('update-profile')">Your profile is registered with {{ user.email }} <icon-edit/></p>

      <p class="text" @click="$router.push('update-profile')">Your password is <span>*********</span> <icon-edit/></p>

      <p class="text" @click="$router.push('update-profile')">
        Here is your profile image, which you will be shown in the community.
      </p>

      <div class="content-avatar" @click="takePicture()">
        <img :src="user.avatar_url" v-if="user.avatar_url" />
        <img src="@/assets/avatar.svg" v-if="!user.avatar_url" />
        <icon-edit/>
      </div>
    </div>

    <div class="footer">
      <v-btn text @click="dialog = true"> Delete all data </v-btn>

      <v-btn text @click="logout()"> Logout </v-btn>
    </div>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="328"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete all data
        </v-card-title>
        <v-card-text>all data will be deleted, are you sure you want that?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#019cec"
            @click="dialog = false"
            :disabled="loadingDialog"
          >
            <v-progress-circular indeterminate color="#00000042" :width="2" :size="20" v-if="loadingDialog" />
            <p v-else>No</p>
          </v-btn>
          <v-btn
            outlined
            color="#019cec"
            @click="deleteUser()"
            :disabled="loadingDialog"
          >
            <v-progress-circular indeterminate color="#00000042" :width="2" :size="20" v-if="loadingDialog"/>
            <p v-else>Yes</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from "@/services/user.service";

import { Camera, CameraResultType } from '@capacitor/camera';

export default {
  name: "Profile",
  data: () => ({
    dialog: false,
    loadingDialog: false,
  }),
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    async takePicture() {
      try {
        await Camera.requestPermissions(["camera", "photos"]);

        const image = await Camera.getPhoto({
          quality: 50,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          webUseInput: true,
        });

        const response = await UserService.registerAvatar(
          image,
          this.$store.getters['user/token']
        );

        this.$store.dispatch("user/updateUser", response.data);

      } catch (error) {
        console.error("error", error)
      }
    },
    logout() {
      this.$store.dispatch("user/logout", this.form);

      this.$router.push({
        name: "Login",
      });
    },

    async deleteUser() {
      this.loadingDialog = true;

      await UserService.delete();

      this.$store.dispatch("user/logout");

      this.loadingDialog = false;
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style lang="scss">
.container-profile {
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #27083a;

  padding: 46px 50px 34px 24px;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin: 10px 30px 30px 0px;

    color: #ffb3fa;
  }

  .text {
    font-size: 18px;
    line-height: 27px;

    margin-bottom: 20px;

    color: #ffb3fa;

    span {
      color: #019cec;

      text-decoration: underline;
    }
  }
  .content-avatar {
    margin: 10px 0px;
  }
  img {
    width: 90px;
    height: 90px;

    border-radius: 50%;

    background: #ffb3fa;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .v-btn {
      color: #019cec;
    }
  }
}
.v-dialog {
  .v-card {
    border-radius: none !important;
    background-color: #27083A;

    .v-card__title {
      font-weight: 400;
      font-size: 29px;
      line-height: 34.8px;
    }
  }
}
</style>
