<template>
  <div class="container-lineup">
    <div class="header">
      <v-icon color="beach_floor" large @click="$router.go(-1)"
        >arrow_back</v-icon
      >

      <p class="title">Lineup</p>
    </div>

    <v-expansion-panels accordion dark>
      <v-expansion-panel v-for="category of categories" :key="category.category">
        <v-expansion-panel-header>{{category.category_name}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Item v-for="schedule in category.schedules" :key="schedule.id" :program="schedule"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import Item from "./Item";

  import SchedulesService from "@/services/schedules.service";

  export default {
    name: "Lineup",
    components: {
      Item,
    },
    data: () => ({
      categories: [],
    }),
    mounted() {
      this.initialize();
    },
    methods: {
      async initialize() {
        try {
          const response = await SchedulesService.lineup();

          this.categories = response.data;
        } catch (error) {
          console.error(error);
          this.$router.go(-1);
        }
      },
    }
  };
</script>

<style lang="scss">
.container-lineup {
  height: 100vh;

  overflow-y: scroll;

  background-color: #27083a;

  .header {
    padding: 46px 50px 30px 24px;
  }

  color: #31ece9;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
  }

  .v-expansion-panels {
    border-top: 1px solid #31ece9;
  }

  .v-expansion-panel {
    background-color: transparent !important;
    color: #31ece9 !important;

    border-radius: 0px;

    .v-expansion-panel-header {
      font-size: 18px;
      line-height: 27px;

      border-bottom: 1px solid #31ece9;

      .mdi:before, .mdi-set {
        color: #31ece9;
      }
    }
  }
}
</style>
