<template>
  <div class="container-update-profile">
    <div class="header">
      <div @click="$router.go(-1)">
        <icon-arrow color="#ffb3fa" :left="true"/>
      </div>

      <p class="title">Update profile</p>
    </div>

    <v-snackbar v-model="showMessage" :top="true">
      <span>{{ errorMessage }}</span>
    </v-snackbar>

    <form class="form" autocomplete="off">
      <label>How do you want to be called?</label>
      <v-text-field
        v-model="form.name"
        placeholder="Name"
        color="#ffb3fa"
        outlined
        :error-messages="nameErrors"
      />

      <label>What is your email?</label>
      <v-text-field
        v-model="form.email"
        placeholder="E-mail"
        color="#ffb3fa"
        outlined
        :error-messages="emailErrors"
      />

      <label>Password</label>
      <v-text-field
        v-model="form.password"
        placeholder="Password"
        color="#ffb3fa"
        outlined
        type="password"
        :error-messages="passwordErrors"
      />

      <label>Confirm password</label>
      <v-text-field
        v-model="form.password_confirm"
        placeholder="Confirm password"
        color="#ffb3fa"
        outlined
        type="password"
        :error-messages="passwordConfirmErrors"
      />
    </form>

    <v-btn color="#019cec" dark @click="onSubmit()">save changes</v-btn>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

import UserService from "@/services/user.service";

export default {
  name: "resgister-informations",
  mixins: [validationMixin],
  data: () => ({
    errorMessage: "",
    showMessage: false,
    form: {}
  }),
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        minLength: minLength(6),
      },
      password_confirm: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Name is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Invalid email");
      !this.$v.form.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.form.password_confirm.$dirty) return errors;
      !this.$v.form.password_confirm.sameAsPassword &&
        errors.push(
          "Password confirmation is not compatible with the password"
        );
      return errors;
    },
  },
  mounted() {
    this.form = this.user
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          this.form.email = this.form.email.toLowerCase();

          const response = await UserService.update(this.form);

          this.errorMessage = "saved changes";
          this.showMessage = true;

          this.$store.dispatch("user/login", response);

        } catch (error) {
          if (error.response.data.error[0]) {
            this.errorMessage = error.response.data.error[0];
            this.showError = true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.container-update-profile {
  height: 100vh;

  overflow-y: scroll;

  padding: 46px 50px 34px 24px;

  background-color: #27083a;

  color: #ffb3fa;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    margin-top: 10px;
    margin-bottom: 30px;
  }

  .form {
    width: 100%;

    display: flex;
    flex-direction: column;

    .v-text-field--outlined fieldset {
      border: none;
    }

    .v-input {
      .v-input__slot {
        background: #402d4f;
      }
    }

    label {
      margin-bottom: 2px;

      text-transform: uppercase;

      font-size: 14px;
      line-height: 16px;
    }

    input {
      color: #ffb3fa;

      padding-left: 10px;

      font-size: 18px;
    }

    ::placeholder {
      color: #ffb3fa;
    }

    button {
      font-size: 18px;

      height: 40px;
    }

    a {
      margin-top: 24px;
    }

    .confirm-terms {
      display: flex;

      input {
        width: 20px;
        height: 20px;
      }
      p {
        width: 65%;
        margin-left: 10px;

        font-size: 16px;

        a {
          color: #019cec;
        }
      }
    }
  }
}
</style>
